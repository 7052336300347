<template>
  <div class="checkout " v-show="isLoggedIn">
    <div class="object-bottom bg-no-repeat bg-cover md:h-60" v-if="settings" :style="{ 'background-color': settings.colors.primaryColor }">
      <div class="container p-5 mx-auto md:max-w-6xl">
        <!-- <p class="text-3xl font-semibold md:text-6xl">Coming Soon</p>
        <p class="pt-3">Sorry for the inconvenience</p>
        <a class="underline" href="/">Home</a> -->
          <p class="pb-2 font-sans text-2xl text-white md:text-4xl font-extra-bold md:mt-3 md:w-120">
            Order for a parcel pick up today
          </p>
          <p class="mb-5 text-white font-extralight">Send parcels or packages to friends and families.</p>
          <a href="/parcel-order" v-if="settings" class="px-3 py-2 text-sm font-black text-white rounded-lg shadow-sm" :style="{ 'background-color': settings.colors.accentColor }">Order Now</a>
        <!-- <img src="/img/parcel.jpg" class="h-60" alt=""> -->
      </div>
    </div>
    <div class="container max-w-6xl p-5 mx-auto">
        <p class="mt-5 text-2xl font-medium text-center">Track your package</p>
        <!-- <div class="flex items-center" v-if="settings">
          <a href="/parcel-order" class="flex items-center h-8 p-5 text-center text-white rounded-md shadow-md" :style="{ 'background-color': settings.colors.primaryColor }">Parcel Delivery</a>
        </div> -->
        
      
      <div class="flex p-3 mt-2 bg-white rounded-md shadow">
          <!-- <span class="flex items-center justify-end w-auto p-2 text-gray-500">
              <SearchIcon class="self-center w-6 h-6 text-gray-200 md:w-6 md:h-6" aria-hidden="true" />
          </span> -->
          <input class="w-full p-2 border-white" v-model="search" type="text" placeholder="search by code">
          <div @click="some" class="flex items-center p-2 pl-4 pr-4 cursor-pointer">
              <SearchIcon class="self-center w-6 h-6 font-black text-black md:w-6 md:h-6" aria-hidden="true" />
          </div>
      </div>
      <div class="container flex flex-col py-5">
        <p class="text-sm font-light">Recent Orders</p>
        <a :href="$router.resolve({name: 'OrderDetails', params: { id: order.id, slug: sanitizeTitle(`${order.code}`) }}).href" class="w-full p-5 my-5 rounded-lg shadow-md " v-for="order in orders" :key="order.id">
          <div class="flex justify-between pb-2 border-b">
            <p class="text-sm font-semibold">{{ order.code }}</p>
            <p class="text-sm font-light">{{ currency }}{{ order.total }}</p>
          </div>
          <div class="flex justify-between">
            <div class="my-2">
              <p class="text-sm font-semibold">{{ order.vendor.name }}</p>
              <p class="text-sm font-light" v-if="order.package_type">{{ order.package_type.name }}</p>
            </div>
            <div class="my-2">
              <p class="text-sm font-semibold">{{ order.formatted_date }}</p>
              <p class="text-sm font-light">{{ order.payment_method.name }}</p>
            </div>
            <div class="my-2">
              <p class="mb-2 text-sm font-semibold">{{ order.status }}</p>
              <a :href="$router.resolve({name: 'OrderDetails', params: { id: order.id, slug: sanitizeTitle(`${order.code}`) }}).href" v-if="settings" class="px-3 py-2 text-sm font-black text-white rounded-lg shadow-sm" :style="{ 'background-color': settings.colors.accentColor }">View</a>
            </div>
            
          </div>
        </a>
      </div>
    </div>
  </div>
  <Download/> 
</template>

<script>
import axios from 'axios'
import { CreditCardIcon, SearchIcon } from '@heroicons/vue/outline'
import { notify } from "@kyvg/vue3-notification"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Download from '@/components/Downloads.vue'
export default {
  name: 'checkout',
  components: {
    CreditCardIcon,
    SearchIcon,
    Download,
    
  },
  data () {
    return {
        isLoggedIn: false,
        user: null,
        note: null,
        addresses: null,
        methods: null,
        vendor_pickup: false,
        AuthStr : 'Bearer ' + localStorage.getItem('authToken'),
        paymentMethod: null,
        address: null,
        settings: null,
        base_url: this.$store.state.baseUrl,
        search: null,
        settings: null,
        orders: null,
        currency: null
    }
  },

    mounted () {

      axios
      .get(this.base_url + "api/orders?vendor_type_id=" +this.$route.params.id,
       { headers: { Authorization: this.AuthStr },
      })
      .then((response) => {
          this.orders = response.data.data
      })
      .catch((error) => console.log(error));

        axios.get(this.base_url+'api/app/settings')
        .then((response) => {
          this.settings = response.data
          this.currency = this.settings.strings.currency;
        })
        .catch(error => console.log(error))

        axios.get(this.base_url+'api/payment/methods', { 
            'headers': { 'Authorization': this.AuthStr } })
        .then(response => (this.methods = response.data.data))
        .catch(error => console.log(error))

        axios.get(this.base_url+'api/delivery/addresses', 
        { 'headers': { 'Authorization': this.AuthStr } }
            )
        .then(response => (this.addresses = response.data.data))
        .catch(error => console.log(error))
        

        if (localStorage.getItem("authToken")) {
            this.isLoggedIn = true;
            this.user = JSON.parse(localStorage.getItem("user"))
        }

        if(this.isLoggedIn == false) {
            this.$router.push("/login");
            localStorage.removeItem("user")
            localStorage.removeItem("authToken")
        }
    },

    methods: {
      sanitizeTitle(title) {
        var slug = "";
        // Change to lower case
        var titleLower = title.toLowerCase();
        // Letter "e"
        slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
        // Letter "a"
        slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
        // Letter "o"
        slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
        // Letter "u"
        slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
        // Letter "d"
        slug = slug.replace(/đ/gi, "d");
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, "");
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, "-");

        return slug;
      },
    }
    
}
</script>
